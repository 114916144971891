import React from 'react';

type Props = {
  type: string;
  isError: boolean;
  count: number;
};
function CashAmount({ type, isError, count }: Props) {
  return (
    <div className='flex-1 flex flex-col gap-6 p-8 rounded-20 bg-white justify-between items-center'>
      <h4 className={isError ? 'text-warning-100' : ''}>
        {type === 'hp1' ? '오백원' : type === 'bd1' ? '천원권' : '만원권'}
      </h4>
      <p>
        {count} / {type === 'hp1' ? 300 : 1000}
      </p>
    </div>
  );
}

export default CashAmount;
