import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

import touchIcon from 'images/Promotion/touch.png';
import logoIcon from 'images/Promotion/logo.png';
import { loginInfoAtom } from 'store/globalStateAtom';

function PromotionPage() {
  const navigate = useNavigate();
  const [loginInfo] = useAtom(loginInfoAtom);

  const onClick = () => {
    navigate('/language-selection');
  };

  useEffect(() => {
    const handleTouchMove = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => document.removeEventListener('touchmove', handleTouchMove);
  }, []);

  return (
    <main className='relative h-[1920px]'>
      <button onClick={onClick} className='active:opacity-80 w-full h-full '>
        {loginInfo?.promotionVideo ? (
          <video
            preload='metadata'
            autoPlay
            muted
            playsInline
            controls={false}
            loop
            className='w-full h-full object-cover'
          >
            <source src={loginInfo.promotionVideo} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className='w-full h-full flex justify-center items-center bg-mono-10'>
            <img src={logoIcon} className='w-[552px] h-[236px]' alt='ktp' />
          </div>
        )}
        <div className='py-8 px-12 w-[740px] bg-[#0E3B904E] rounded-100 text-head-01 text-white flex items-center gap-4 font-bold absolute bottom-36 left-[50%] -translate-x-[50%] backdrop-blur-md'>
          <img src={touchIcon} alt='touch_here' className='w-20 h-20' />
          Touch to get a Tax Refund
        </div>
      </button>
    </main>
  );
}

export default PromotionPage;
