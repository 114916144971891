import React, { Dispatch, SetStateAction } from 'react';

import { ActiveInput } from 'pages/CashCharging';

type Props = {
  type: string;
  targetCount: number;
  currentCount: number;
  value: string;
  active: boolean;
  setActive: Dispatch<SetStateAction<ActiveInput>>;
};
function ChargingItem({
  type,
  targetCount,
  currentCount,
  value,
  active,
  setActive,
}: Props) {
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex gap-5'>
        <div className='bg-mono-05 py-8 px-12 rounded-20 text-body-01 text-mono-65 font-semibold flex justify-between flex-1'>
          <p>
            {type === 'bd2Count'
              ? '만원권'
              : type === 'bd1Count'
              ? '천원권'
              : '오백원'}
          </p>
          <p>
            {currentCount}/{targetCount}
          </p>
        </div>
        <div className='relative group'>
          <span className='text-body-01 absolute top-[30px] left-12 group-:text-blue-100'>
            +
          </span>
          <input
            name={type}
            value={value}
            className={`py-8 px-12 pl-20 rounded-20 placeholder:text-body-01 text-body-01 w-[194px] outline-none ${
              active ? 'outline outline-4 outline-blue-60' : ''
            } focus:text-mono-100 text-blue-100 before:content-['+']`}
            placeholder={type === 'hp1' ? '00' : '000'}
            onClick={() => setActive(type as ActiveInput)}
          />
        </div>
      </div>
    </div>
  );
}

export default ChargingItem;
