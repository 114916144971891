import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { checkControlCode, CheckControlCodeReqBody } from 'api';
import { Error } from 'api/config';
import { AdminLoginInfo, modalInfoAtom } from 'store/globalStateAtom';

type Props = {
  setAdminLoginInfo: any;
  setIsError: Dispatch<SetStateAction<boolean>>;
};
function useCheckControlCode({ setAdminLoginInfo, setIsError }: Props) {
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const { t } = useTranslation();

  return useMutation<
    AdminLoginInfo,
    AxiosError<Error>,
    CheckControlCodeReqBody
  >((payload) => checkControlCode(payload), {
    retry: false,
    onSuccess: (adminInfo) => {
      setIsError(false);
      setAdminLoginInfo(adminInfo);
    },
    onError: (err) => {
      const errCode = err.response?.data.code;
      if (errCode === 'K0001' || errCode === 'K0004') {
        setIsError(true);
        return;
      }
      if (err.message === 'Network Error') {
        setModalInfo({
          title: t('error_network_title'),
          description: t('error_network_desc'),
          icon: 'ALERT',
        });
      } else {
        setModalInfo({
          title: '에러가 발생했습니다.',
          description: t('error_default_desc'),
          icon: 'ERROR',
        });
      }
    },
  });
}

export default useCheckControlCode;
