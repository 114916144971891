import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { fetchKioskStatus } from 'api';
import {
  KioskStatus,
  kioskStatusAtom,
  modalInfoAtom,
} from 'store/globalStateAtom';

function useFetchKioskStatus() {
  const { t } = useTranslation();
  const [, setKioskStatus] = useAtom(kioskStatusAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);

  //@ts-ignore
  return useMutation<KioskStatus, AxiosError, string>(
    (payload) => fetchKioskStatus(payload),
    {
      retry: false,
      onSuccess: (data) => {
        setKioskStatus(data);
      },
      onError: (err) => {
        if (err.message === 'Network Error') {
          setModalInfo({
            title: t('error_network_title'),
            description: t('error_network_desc'),
            icon: 'ALERT',
          });
        } else {
          setModalInfo({
            title: '에러가 발생했습니다.',
            description: t('error_default_desc'),
            icon: 'ERROR',
          });
        }
      },
    }
  );
}

export default useFetchKioskStatus;
