import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAtom } from 'jotai';

import LoadingView from 'components/common/LoadingView';
import NumberKeypad from 'components/common/NumberKeypad';
import settingIcon from 'images/common/setting.png';
import closeIcon from 'images/common/close.png';
import useCheckControlCode from 'hooks/useCheckControlCode';
import { adminLoginInfoAtom, loginInfoAtom } from 'store/globalStateAtom';

function ControlCodeFormModal() {
  const navigate = useNavigate();
  const [loginInfo] = useAtom(loginInfoAtom);
  const [adminLoginInfo, setAdminLoginInfo] = useAtom(adminLoginInfoAtom);

  const [controlCode, setControlCode] = useState('');
  const [isError, setIsError] = useState(false);

  const { mutate, isLoading } = useCheckControlCode({
    setAdminLoginInfo,
    setIsError,
  });

  const onKeyPress = (key: any) => {
    setIsError(false);
    if (key === 'Back') {
      setControlCode((prevInput) => prevInput.slice(0, -1));
      return;
    }
    if (key === 'Clear') {
      setControlCode('');
      return;
    }
    setControlCode((prevInput) => {
      return prevInput.length === 14 ? prevInput : prevInput + key;
    });
  };
  const onClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (controlCode.length !== 14) {
      return;
    }
    mutate({
      controlCode,
    });
  }, [controlCode]);

  return (
    <>
      {!adminLoginInfo.controlCode && (
        <div className='fixed z-50 w-full h-full top-0 left-0 flex flex-col justify-center items-center bg-mono-50'>
          <div className='w-[840px] bg-white rounded-20 flex-col items-center py-40 px-12 text-center relative'>
            {isLoading && <LoadingView />}
            <button
              onClick={onClose}
              className='mb-20 absolute top-12 right-12'
            >
              <img src={closeIcon} width={48} height={48} alt='close' />
            </button>
            <img
              src={settingIcon}
              width={120}
              height={120}
              alt='setting'
              className='mx-auto'
            />
            <h2 className='text-head-01 font-bold mt-16 mb-3 text-mono-80'>
              키오스크 설정을 위해
              <br />
              [사업자 번호 10자리+0000]를
              <br />
              입력해주세요.
            </h2>
            <input
              className={`py-8 px-12 rounded-20 placeholder:text-[42px] text-[42px] w-full mt-16 outline outline-4 ${
                isError ? 'outline-warning-100' : ''
              } outline-${isError ? 'warning-100' : 'blue-60'}`}
              value={controlCode}
              maxLength={10}
            />
            {isError && (
              <p className='text-warning-100 text-body-02 text-left mt-4 ml-8'>
                사업자 번호를 다시 확인해주세요.
              </p>
            )}
            <NumberKeypad onKeyPress={onKeyPress} containerStyle='mt-20' />
          </div>
        </div>
      )}
    </>
  );
}

export default ControlCodeFormModal;
