import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { completeRefunds, CompleteRefundsReqBody } from 'api';
import { Error } from 'api/config';
import { modalInfoAtom } from 'store/globalStateAtom';

function useCompleteRefunds() {
  const navigate = useNavigate();
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const { t } = useTranslation();

  return useMutation<number, AxiosError<Error>, CompleteRefundsReqBody>(
    (payload) => completeRefunds(payload),
    {
      retry: false,
      onSuccess: () => {
        navigate('/refund/complete');
      },
      onError: (err) => {
        if (err.message === 'Network Error') {
          setModalInfo({
            title: t('error_network_title'),
            description: t('error_network_desc'),
            icon: 'ALERT',
          });
        } else {
          setModalInfo({
            title: '에러가 발생했습니다.',
            description: t('error_default_desc'),
            icon: 'ERROR',
          });
        }
      },
    }
  );
}

export default useCompleteRefunds;
