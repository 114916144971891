import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAtom } from 'jotai';

import Layout from 'components/common/Layout';
import LoadingView from 'components/common/LoadingView';
import NumberKeypad from 'components/common/NumberKeypad';
import ChargingItem from 'components/CashCharging/ChargingItem';
import arrowLeft from 'images/common/chevron_left_black.png';
import check from 'images/common/check.png';
import {
  adminLoginInfoAtom,
  KioskStatus,
  kioskStatusAtom,
  loginInfoAtom,
  modalInfoAtom,
} from 'store/globalStateAtom';
import useUpdateKioskStatus from 'hooks/useUpdateKioskStatus';

export type ActiveInput = '' | 'hp1Count' | 'bd1Count' | 'bd2Count';
function CashCharging() {
  const navigate = useNavigate();
  const [loginInfo] = useAtom(loginInfoAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const [kioskStatus, setKioskStatus] = useAtom(kioskStatusAtom);
  const [adminLoginInfo] = useAtom(adminLoginInfoAtom);
  const [activeInput, setActiveInput] = useState<ActiveInput>('');

  const { bd1TotalCount, bd2TotalCount, hp1TotalCount } =
    kioskStatus as KioskStatus;

  const { mutate, isLoading, isSuccess } = useUpdateKioskStatus();
  const [inputValues, setInputValues] = useState({
    hp1Count: '',
    bd1Count: '',
    bd2Count: '',
  });
  const { hp1Count, bd1Count, bd2Count } = inputValues;

  const itemArray = [
    {
      type: 'bd2Count',
      targetCount: 1000,
      currentCount: bd2TotalCount,
      value: bd2Count,
    },
    {
      type: 'bd1Count',
      targetCount: 1000,
      currentCount: bd1TotalCount,
      value: bd1Count,
    },
    {
      type: 'hp1Count',
      targetCount: 300,
      currentCount: hp1TotalCount,
      value: hp1Count,
    },
  ];

  const onSave = () => {
    const newKioskStatus = {
      ...kioskStatus,
      hp1TotalCount: hp1TotalCount + +hp1Count,
      bd1TotalCount: bd1TotalCount + +bd1Count,
      bd2TotalCount: bd2TotalCount + +bd2Count,
    };
    mutate({
      type: 'DEPOSIT',
      controlCode: loginInfo.controlCode,
      managerCode: adminLoginInfo.controlCode,
      ...newKioskStatus,
    });
    setKioskStatus(newKioskStatus);
  };

  const onKeyPress = (key: any) => {
    if (activeInput === '') {
      return;
    }
    if (key === 'Back') {
      setInputValues((prev) => ({
        ...prev,
        [activeInput]: prev[activeInput].slice(0, -1),
      }));
      return;
    }
    if (key === 'Clear') {
      setInputValues((prev) => ({
        ...prev,
        [activeInput]: '',
      }));
      return;
    }

    setInputValues((prev) => ({
      ...prev,
      [activeInput]: prev[activeInput] + key,
    }));
  };

  const onCancel = () => {
    setModalInfo({
      title: '현금 보충을 취소할까요?',
      description: `취소하기를 누르면 보충 내역은 저장되지 않습니다.\n현금 보충을 계속하려면 닫기를 눌러주세요.`,
      icon: 'ALERT',
      btnText: '충전 계속하기',
      subBtnText: '취소하기',
      subBtnCallback: () => navigate(-1),
    });
  };

  const setMaximumIfOverflowing = () => {
    if (activeInput === 'hp1Count' && +hp1Count > 300 - hp1TotalCount) {
      setInputValues((prev) => ({
        ...prev,
        [activeInput]: String(300 - hp1TotalCount),
      }));
      return;
    }
    if (
      activeInput !== 'hp1Count' &&
      +(activeInput === 'bd1Count' ? bd1Count : bd2Count) >
        1000 - (activeInput === 'bd1Count' ? bd1TotalCount : bd2TotalCount)
    ) {
      setInputValues((prev) => ({
        ...prev,
        [activeInput]: String(
          1000 - (activeInput === 'bd1Count' ? bd1TotalCount : bd2TotalCount)
        ),
      }));
      return;
    }
  };
  useEffect(() => {
    if (!isSuccess) return;
    navigate(-1);
  }, [isSuccess]);

  useEffect(() => {
    setMaximumIfOverflowing();
  }, [inputValues]);

  return (
    <Layout>
      {isLoading && <LoadingView />}
      <h2 className='text-mono-80 text-head-01 font-bold mt-60 mb-20'>
        현금 보충
      </h2>
      <div className='flex flex-col gap-5'>
        {itemArray.map((v, i) => (
          <ChargingItem
            key={i}
            {...v}
            active={activeInput === v.type}
            setActive={setActiveInput}
          />
        ))}
      </div>
      <div className='flex justify-between'>
        <button
          onClick={onCancel}
          className='text-subHead-01 flex items-center bg-white py-6 px-28 rounded-100 text-mono-80 font-semibold mt-36 shadow-default'
        >
          <img src={arrowLeft} width={36} height={36} alt='arrow_left' />
          취소
        </button>
        <button
          onClick={onSave}
          disabled={!(bd1Count || bd2Count || hp1Count)}
          className='text-subHead-01 flex items-center disabled:bg-blue-20 bg-blue-100 active:bg-blue-150 text-white py-6 px-28 rounded-100 font-semibold mt-36 shadow-default'
        >
          <img src={check} width={36} height={36} alt='arrow_right' />
          저장하기
        </button>
      </div>
      <NumberKeypad onKeyPress={onKeyPress} containerStyle='mt-20' />
    </Layout>
  );
}

export default CashCharging;
