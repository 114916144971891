import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { login, LoginReqParams, UserInfo } from 'api';
import { Error } from 'api/config';
import { loginInfoAtom, modalInfoAtom } from 'store/globalStateAtom';
import useFetchKioskStatus from 'hooks/useFetchKioskStatus';

function useLogin() {
  const { t } = useTranslation();
  const [, setLoginInfo] = useAtom(loginInfoAtom);
  const [, setModalInfo] = useAtom(modalInfoAtom);
  const navigate = useNavigate();
  const { mutate } = useFetchKioskStatus();

  return useMutation<UserInfo, AxiosError<Error>, LoginReqParams>(
    (payload) => login(payload),
    {
      retry: false,
      onSuccess: async (data) => {
        await mutate(data.controlCode);
        setLoginInfo(data);
        setModalInfo({
          icon: 'CHECK',
          title: `${data.name}님 반갑습니다.`,
          description: '로그인에 성공했습니다.',
          btnCallback: async () => {
            document.documentElement.requestFullscreen();
            navigate('/promotion');
          },
        });
      },
      onError: (err) => {
        const errCode = err.response?.data.code;
        if (errCode === 'H0001' || errCode === 'K0001') {
          setModalInfo({
            icon: 'ALERT',
            title: '사업자 번호를 확인해주세요.',
            description:
              '로그인에 실패했습니다. 올바른 사업자 번호를 입력해주세요.',
          });
        } else if (err.message === 'Network Error') {
          setModalInfo({
            title: t('error_network_title'),
            description: t('error_network_desc'),
            icon: 'ALERT',
          });
        } else {
          setModalInfo({
            title: '에러가 발생했습니다.',
            description: t('error_default_desc'),
            icon: 'ERROR',
          });
        }
      },
    }
  );
}

export default useLogin;
